import { course } from 'data/api';
import { useQuery, useMutation } from 'react-query';
import { POST } from 'api/common';

export const getFilterModulesData = async (params) => {
    console.log(params, "params from QUERY");
    const data = await POST(`${course.get_filter_modules}`, params, () => {
        // console.log("FETCHED");
    });
    return data
};

export const useFilterModuleQuery = (params) => {
    return useQuery('GetFilterModules', () => getFilterModulesData(params));
};

export const useFilterModuleMutation = () => {
    return useMutation(getFilterModulesData);
};
