import React, { useState } from 'react'
import Svgs from 'svgs'

const Input = ({ className, onChange, label, placeholder, type, name, form, accept, disabled }) => {
    const [Show, setShow] = useState(false)
    return (
        <div>
            {label && <label className="block mb-1 text-sm font-medium">{label}</label>}
            <div className='flex items-center gap-2 relative'>
                <input
                    name={name}
                    disabled={disabled}
                    value={form?.values && type == "file" ? "" : form?.values[name]}
                    onChange={form ? form.handleChange : onChange}
                    type={type ? type == "password" ? Show ? "text" : type : type : "text"}
                    accept={accept}
                    className={`${className} ${form?.errors[name] && "border-red-600"} bg-gray-50 border border-[#00000099] text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none`}
                    placeholder={placeholder}
                />
                {
                    type == "password" && <div onClick={() => setShow(_ => !_)} className={`${Show && "text-[#0053a5]"} absolute right-2 cursor-pointer`}>
                        <Svgs.PasswordEye />
                    </div>
                }
            </div>
            {form?.errors[name] && <p className='text-red-600 pt-1 text-right text-xs'>{form?.errors[name]}</p>}
        </div>
    )
}

Input.defaultProps = {
    form: null, // Change the default value to null instead of an object with values set to false
}

export default Input
