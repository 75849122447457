import { course } from 'data/api';
import { useQuery, useMutation } from 'react-query';
import { GET } from 'api/common';

export const getModulesData = async () => {
    const data = await GET(course.get_modules, null);
    return data;
};

export const useGetModuleQuery = () => {
    return useQuery('GetModules', getModulesData);
};

export const useGetModuleMutation = () => {
    return useMutation(getModulesData);
};
