import { GET } from 'api/common';
import { student } from 'data/api';
import { useMutation, useQuery } from 'react-query';

// Fetch student forms
export const getStudentForms = async () => {
    const data = await GET(student.get_student_forms, null);
    return data;
};

// Custom hook for fetching student forms
export const useGetStudentFormsQuery = () => {
    return useQuery('getStudentForms', getStudentForms);
};

// Custom hook for mutating student forms
export const useGetStudentFormsMutation = () => {
    return useMutation(getStudentForms);
};