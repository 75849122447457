import { POST } from 'api/common';
import { student } from 'data/api';
import { toast } from 'react-hot-toast';
import { useQuery, useMutation } from 'react-query';

export const addStudentModule = async (params) => {
    const URL = student.add_student_course
    const response = await POST(URL, params, () => {
        toast.success(`Module Purchased successfully!`);
    });
    return response;
};

export const useAddStudentModuleQuery = () => {
    return useQuery('AddStudentModule', addStudentModule);
};

export const useAddStudentModuleMutation = () => {
    return useMutation(addStudentModule);
};
