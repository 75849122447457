import { useStudentModuleQuery } from 'api/modules/student-course';
import Card from 'components/common/elements/card';
import StudentContainer from 'components/layout/student-container'
import React, { useEffect, useState } from 'react'

const MyLearning = () => {
  const [id, setid] = useState(false)
  const { data: Module, isLoading: isGetModuleLoading, refetch: refetchModule } = useStudentModuleQuery(id);
  useEffect(() => {
    setid(JSON.parse(localStorage.getItem("data"))?.student?.student_id);
  }, [])

  useEffect(() => {
    if (id) {
      refetchModule();
    }
  }, [id])

  return (
    <StudentContainer>
      <div className='container flex flex-col gap-4 py-[4rem]'>
        <h1 className='font-extrabold text-3xl'>My Modules</h1>
        <div className='grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3'>
          {
            isGetModuleLoading ? "loading..." : Module?.data.length ? Module?.data.map(ele => {
              return <Card url={`/student/video/${ele?.id}`} data={ele} />
            }) : ""
          }
        </div>
      </div>
    </StudentContainer>
  )
}

export default MyLearning